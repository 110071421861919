import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

import Heading from 'components/alumni/Heading';
import ClassNotes, {
  Props as ClassNotesProps
} from 'components/alumni/ClassNotes';
import Memoriam from 'components/alumni/Memoriam';
import Events, { Props as EventsProps } from 'components/alumni/Events';
import MentorVolunteer from 'components/alumni/MentorVolunteer';

interface Props {
  data: ClassNotesProps &
    EventsProps & {
      matthewChitwood: Core.ImageAttributes;
      matthewChitwoodPrimary: Core.ImageAttributes;
      matthewChitwoodSquare: Core.ImageAttributes;
      alexParcan: Core.ImageAttributes;
    };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const {
    classNotes,
    events,
    matthewChitwood,
    matthewChitwoodPrimary,
    matthewChitwoodSquare,
    alexParcan
  } = props.data;

  return (
    <Layout departmentSlug="alumni" title="Alumni">
      <Heading title="Meet one alumnus who advocates for asylum seekers and human rights issues and another who is chronicling the massive transformation in China from his home in a remote village in Yunnan province … Read about noteworthy happenings in the lives of former classmates … Catch up on SAIS alumni events unfolding around the globe." />

      <Core.ContentWrapper>
        <ClassNotes classNotes={classNotes} />
        <Core.HorizontalRule />
        <div id="transformation-in-rural-china" />
        <Core.Flag>Alumni Profile</Core.Flag>
        <Core.PrimaryHeading>Transformation in Rural China</Core.PrimaryHeading>
        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              image={matthewChitwood}
              contentSectionPlacement="marginalia"
              alt="Matthew Chitwood"
            />
          }
        >
          <p>
            <Core.LeadIn>“Changes have been taking place</Core.LeadIn> in
            Bangdong village, where I live. Spring tea season has come and gone.
            Some have married, and some have passed. The township started a
            trash collection program, and my neighbor ate their dog.”
          </p>
          <p>
            So writes Matthew Chitwood ’11 (HNC Certificate ’10) from the
            mud-walled home he occupies in a remote mountain community of 350
            souls in China’s Yunnan province—a place he describes as the
            country’s “wild southwest.” He acknowledges that the last bit of his
            recent report might be a bit jarring for his urban friends.
          </p>
          <p>
            But there are different imperatives in a place where generations
            have depended on subsistence farming or on sending family members to
            faraway cities and even foreign countries to work for months or
            years at a time as migrant laborers. A place where hunger is as
            familiar as the nearly impassable mountains.
          </p>
          <p>
            “It’s definitely a different reality over here regarding food,” he
            says. He explains that his neighbors ate their dog because “they are
            getting into the tea business—processing it at home—and tea leaves
            and dog hair don’t mix.”
          </p>
          <p>
            And, in a place where eating is less a culinary adventure than a
            matter of survival, it is “much more intimate in that people kill
            the animals themselves,” he says. “So there’s still a meaningful
            personal connection, which strikes me as a healthier approach than
            picking up beautifully plastic-wrapped lamb chops at the butcher’s.”
          </p>
        </Core.ContentSection>
        <Core.FullWidthImage
          image={matthewChitwoodPrimary}
          alt="Matthew Chitwood Primary"
        />
        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              image={matthewChitwoodSquare}
              alt="Matthew Chitwood Square"
              contentSectionPlacement="marginalia"
            />
          }
        >
          <p>
            Chitwood moved to Bangdong in late 2017 after being awarded a highly
            competitive fellowship from the Institute of Current World Affairs,
            a nearly century-old Washington, D.C., nonprofit that identifies
            exceptional young people and supports them for two years in pursuing
            their chosen study of a country, region, or globally important
            issue. Only two people are selected every year. Chitwood had spent a
            decade in China’s cities— living, studying, and working, including
            for the Bill & Melinda Gates Foundation and for a number of
            companies offering “transformative” study abroad programs. Inspired
            in part by a 2011 SAIS study trip to Yunnan, he wanted to learn more
            about rural China, which is home to 40 percent of the country’s
            population. And he wanted to chronicle the massive and nearly
            overnight transformation being effected by the Communist Party under
            President Xi Jinping to fulfill his promise to eradicate rural
            poverty by 2020.
          </p>
          <p>
            Chitwood’s beautifully written dispatches, accompanied by photos
            that tell the story of Yunnan’s rich and varied cultures and the
            anachronisms inherent in rapid modernization—the first paved roads
            and tunnels traversing the region, high-speed rail lines, new
            bathrooms and sturdy houses and Internet service and
            electricity—appear regularly on the ICWA website and were posted in
            May as a four-part miniseries on the Los Angeles Review of Books
            China Channel.
          </p>
          <p>
            When his time in Bangdong is up in November, Chitwood plans to write
            a book about the experience, a tribute to all he has learned and to
            the people who have befriended him and shared their stories.
          </p>
          <p>
            Growing up in Spokane, Washington, and studying international
            business at Northwest Nazarene University in Boise, Idaho, Chitwood
            dreamed of traveling but never gave a thought to China until a
            visiting Taiwanese pastor invited him to Taipei to teach English.
            There, he became curious about mainland China, and somehow,
            unexpected and compelling opportunities kept presenting themselves.
            Now he wants to return to the U.S. to write that book. And then, who
            knows?
          </p>
          <p>
            “I seem to live mostly in the present,” he says. “And what has
            always motivated me is connecting with and learning about other
            people. That’s why I learned Spanish, why I learned Mandarin. It
            just seems that, wherever I am, I love where I am, and I love the
            people around me.”
          </p>
          <Core.SignOff>Joan Cramer</Core.SignOff>
          <Core.Share id="transformation-in-rural-china" />
        </Core.ContentSection>
        <Core.HorizontalRule />

        <Core.ContentSection
          marginalia={
            <Core.FullWidthImage
              image={alexParcan}
              alt="Alex Parcan"
              contentSectionPlacement="marginalia"
            />
          }
        >
          <Core.Flag>Alumni Profile</Core.Flag>
          <Core.PrimaryHeading>
            Helping People When They Need Help
          </Core.PrimaryHeading>
          <p>
            <Core.LeadIn>Alex Parcan’s dream</Core.LeadIn> has always been a
            career working with immigrants and promoting human rights to not
            only help individual émigrés but also help shape public policy in
            the U.S. and abroad. At Boston College Law School, where he earned
            his law degree before earning his master&rsquo;s degree from Johns
            Hopkins SAIS in 2012, he volunteered in immigration clinics and
            helped prosecute war crimes at the International Criminal Tribunal
            for the former Yugoslavia. Later, he worked with refugees in
            Thailand.
          </p>
          <p>
            In December 2016, he took a first step toward realizing his dream,
            launching his own practice as an immigration lawyer in Washington,
            D.C. But it wasn’t until a year or so later that he landed the job
            that encapsulated all of the things he wanted to do—as senior staff
            attorney at Human Rights First, the venerable Washington, D.C.-based
            international advocacy organization founded in 1978 as the Lawyers
            Committee for International Human Rights.
          </p>
          <p>
            “What I do is challenging in the current climate, but it’s also
            rewarding,” he says. “Day to day, I’m working with individuals who
            are requesting asylum. But it’s also my job to help further the
            greater policy goals of the organization to advocate for human
            rights generally. So it’s the best of both worlds.”
          </p>
          <p>
            Parcan says that law school taught him to be a lawyer, but SAIS
            taught him how to “lead in the international policy world in a
            really special way. It’s an invaluable skill set, and I don’t think
            you can get it anywhere else.”
          </p>
          <p>
            The son and grandson of Chilean immigrants, Parcan grew up in the
            Washington, D.C., suburbs of Montgomery County, Maryland. His
            parents are both teachers: His father is a college professor, and
            his mother is devoted to her work as a teacher and administrator in
            underserved public schools. He speaks fluent Spanish and French, and
            a little bit of Mandarin (his fianceé is a journalist from Taiwan
            who works in Washington), and he says it means everything to him to
            be able to use skills he has picked up along the way to serve people
            in need.
          </p>
          <p>
            Asked what he wants people to know about his clients, he says:
            “First, that they’re people. And they’re people who’ve suffered
            incredible hardships, things we in our comfortable lives have never
            had to experience.
          </p>
          <p>
            “Second, they are not ‘lawbreakers.’ We have a system of laws in
            this country that gives people an opportunity to request asylum, a
            system put in place by our grandparents after they witnessed the
            horrors of World War II.
          </p>
          <p>
            “Third, things can change in any country very quickly, and any of us
            could find ourselves suddenly desperately dependent on the kindness
            of strangers. Helping people when they need help, being nice to
            people, should matter to every single person in the world.”
          </p>
        </Core.ContentSection>

        <Core.HorizontalRule />

        <Events events={events} />

        <Core.HorizontalRule />

        <Memoriam>
          <Core.ContentSection id="memoriam">
            <Core.PrimaryHeading>In Memoriam</Core.PrimaryHeading>
            <Core.LargeText fontStyle="sans">
              John A. Bleyle ’68
              <br />
              William Culbert ’49
              <br />
              Lola Gulomova ’01
              <br />
              Kiyo-Aki Kikuchi ’51
              <br />
              Peter Kilby ’59
              <br />
              Peter Magowan ’68
              <br />
              Puay Tang PhD ’93
              <br />
              Jan Wlodarkiewicz ’59
            </Core.LargeText>
          </Core.ContentSection>
        </Memoriam>

        <Core.HorizontalRule />

        <MentorVolunteer />

        <Core.MoreResources>
          To see upcoming global alumni events, visit{' '}
          <a
            href="http://www.sais-jhu.edu/atoz/alumni-news-events#events"
            target="_blank"
            rel="noopener noreferrer"
          >
            sais-jhu.edu/atoz/alumni-news-events
          </a>
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    classNotes: allFile(
      filter: {
        relativePath: { regex: $directory }
        name: { eq: "class-notes" }
      }
    ) {
      ...ClassNotes
    }
    events: allFile(
      filter: { relativePath: { regex: $directory }, name: { eq: "events" } }
    ) {
      ...Events
    }
    matthewChitwood: file(
      relativePath: { regex: $directory }
      name: { eq: "matthew-chitwood" }
    ) {
      ...MarginaliaImage
    }
    matthewChitwoodPrimary: file(
      relativePath: { regex: $directory }
      name: { eq: "matthew-chitwood-primary" }
    ) {
      ...FullWidthImage
    }
    matthewChitwoodSquare: file(
      relativePath: { regex: $directory }
      name: { eq: "matthew-chitwood-square" }
    ) {
      ...MarginaliaImage
    }
    alexParcan: file(
      relativePath: { regex: $directory }
      name: { eq: "alex-parcan" }
    ) {
      ...MarginaliaImage
    }
  }
`;

export default DepartmentContent;
