import './MentorVolunteer.scss';

import React from 'react';

import * as Core from 'components/core';

const MentorVolunteer: React.FC = () => {
  return (
    <div className="MentorVolunteer">
      <Core.SecondaryHeading>Volunteer to Be a Mentor</Core.SecondaryHeading>
      <div className="MentorVolunteer-content">
        <svg
          className="MentorVolunteer-content-icon"
          x="0px"
          y="0px"
          viewBox="0 0 72 72"
        >
          <path
            fill="#41AD49"
            d="M36,70.76C16.86,70.76,1.24,55.14,1.24,36C1.24,16.86,16.86,1.24,36,1.24c19.14,0,34.76,15.62,34.76,34.76
            C70.76,55.14,55.14,70.76,36,70.76 M49.86,55.27c-1.63,2.6-3.61,5.2-5.95,7.82l0.03-3.12c2.05-2.6,3.75-5.2,5.13-7.65H44l0.02-2.2
            h3.89c0.67,1.14,1.25,1.66,2.3,2.46L49.86,55.27z M53.01,23.41c1.29,3.1,2.14,6.22,2.51,9.37c-4.33,0.55,0.36,4.66-3.96-7.07
            C52.04,24.95,52.56,24.19,53.01,23.41 M48.65,15.4c0.51,0.77,1.01,1.54,1.48,2.33c-0.63,0.33-1.07,0.88-1.38,1.26l-0.04,0.05
            l0.01,0c-0.04,0.05-0.1,0.14-0.2,0.26c-0.17-0.3-0.34-0.59-0.52-0.89l0.46-1.6l0.02-0.07C48.48,16.74,48.61,16.16,48.65,15.4
             M22.21,50.12h8.48l-0.16,2.2H23.1c1.73,3.06,3.97,6.15,6.75,9.26l-0.22,3c-2.58-2.72-4.76-5.43-6.56-8.13L22.21,50.12z
             M33.63,3.53C22.27,4.36,12.2,11.1,7.03,21.18h13.14C23.43,14.49,28.31,8.67,33.63,3.53 M42.51,67.9c2.62-0.54,5.14-1.39,7.49-2.52
            l1.65-12.89c0,0-0.44-0.36-1.32-1.02c-0.88-0.66-1.63-2.16-1.8-2.65c-0.16-0.49-0.5-1.84-1.73-3.27c-1.23-1.44-1.49-0.94-1.73-1.7
            c-0.24-0.76,0.84-0.97,0.84-0.97s2.84,0.06,4.51,3.44c0.6-0.01,0.86-1.72,0.91-2.2c0.05-0.5-0.06-1-0.06-1l-1.12-6.01
            c0,0-0.24-1.02,0.68-1.27c0.92-0.25,1.21,0.59,1.21,0.59s1.39,5.47,1.53,6.15c0.14,0.67,0.69-0.04,0.69-0.04l0.56-6.79
            c0,0-0.02-1.13,1.19-1.12c1.21,0.02,0.93,1.75,0.93,1.75s-0.47,5.85-0.51,6.53c-0.05,0.68,0.46,0.12,0.46,0.12s2.08-5.27,2.28-5.83
            c0.22-0.56,0.54-1.16,1.39-0.94c0.84,0.22,0.33,2.03,0.33,2.03s-1.87,5.39-2,5.8c-0.12,0.41,0.18,0.36,0.18,0.36
            s3.01-3.81,3.25-4.08c0.24-0.26,0.71-0.85,1.48-0.33c0.77,0.52-0.48,2.19-0.48,2.19s-2.33,3.27-2.54,3.59
            c-0.22,0.32-0.21,0.53-0.21,0.53l-0.39,2.64c-0.88,3.55-2.7,4.37-2.7,4.37L57,60.84c2.85-2.41,5.27-5.3,7.15-8.52h-4.2
            c0.4-0.56,0.8-1.29,1.14-2.2h4.23c1.92-3.96,3.06-8.37,3.21-13.02h-6.16c0.05-0.71-0.02-1.63-0.61-2.21h6.77
            c-0.14-4.07-1.03-7.95-2.53-11.51H53.03c0.26-0.46,0.7-1.3,0.92-2.2h11.02C59.82,11.16,49.82,4.42,38.54,3.54
            c2.54,2.46,5.05,5.15,7.33,8.05c-0.84,0.01-1.49,0.33-1.99,0.82c-1.81-2.35-3.87-4.68-6.16-6.94v3.88
            c-2.57,0.48-2.86,2.97-2.85,3.36l-0.02,0.02V5.39c-2.3,2.25-4.38,4.5-6.22,6.76c-0.78,0.43-1.15,1.06-1.32,1.67
            c-1.86,2.44-3.43,4.9-4.71,7.35h6.04l0.54,2.2h-7.64c-0.17,0.39-0.34,0.77-0.5,1.16c-0.89,0.37-2.48,1.34-1.71,3.35
            c0.11,0.3,0.22,0.53,0.34,0.71c-0.11,0.39-0.2,0.78-0.29,1.16c-0.63,0.13-1.07,0.47-1.39,0.92c-0.17,0.25-0.29,0.5-0.37,0.76
            c-0.07-0.39-0.22-0.94-0.53-1.42c0.46-2.22,1.15-4.43,2.08-6.64H5.99c-1.51,3.56-2.4,7.45-2.53,11.51h4.81l0.94,2.21H3.46
            c0.16,4.65,1.29,9.05,3.21,13.02h5.43c0.36,0.2,0.78,0.38,1.16,0.55l0.15,1.65H7.84c2,3.43,4.61,6.47,7.69,8.97l-0.99-11
            c0,0-0.53-0.27-1.59-0.76c-1.06-0.48-2.16-1.85-2.45-2.32c-0.28-0.47-0.93-1.78-2.53-2.98c-1.59-1.2-1.74-0.63-2.16-1.36
            C5.4,42.16,6.46,41.7,6.46,41.7s2.94-0.57,5.42,2.52c0.61-0.16,0.49-1.97,0.43-2.47c-0.06-0.52-0.29-1.02-0.29-1.02L9.5,34.81
            c0,0-0.48-1,0.41-1.47c0.89-0.47,1.37,0.34,1.37,0.34s2.67,5.32,2.97,5.98c0.3,0.66,0.7-0.19,0.7-0.19L14,32.36
            c0,0-0.28-1.16,0.97-1.42c1.25-0.26,1.36,1.59,1.36,1.59s0.84,6.12,0.95,6.83c0.11,0.71,0.51,0.02,0.51,0.02s0.94-5.88,1.03-6.51
            c0.09-0.62,0.29-1.31,1.21-1.28c0.92,0.03,0.8,2.01,0.8,2.01s-0.71,5.97-0.74,6.42c-0.03,0.45,0.27,0.33,0.27,0.33
            s2.23-4.6,2.41-4.92c0.18-0.33,0.54-1.04,1.45-0.67c0.9,0.36,0,2.36,0,2.36s-1.65,3.89-1.8,4.27c-0.15,0.38-0.1,0.59-0.1,0.59
            l0.19,2.81c-0.1,3.85-1.78,5.1-1.78,5.1l2.15,15.9c2.48,1.1,5.13,1.9,7.89,2.34l1.91-26.04c0,0-0.79-0.57-2.36-1.63
            c-1.57-1.06-2.99-3.54-3.32-4.37c-0.32-0.82-1.03-3.1-3.27-5.44c-2.24-2.34-2.64-1.46-3.12-2.74c-0.49-1.28,1.33-1.73,1.33-1.73
            s4.87-0.15,8.04,5.47c1.04-0.08,1.29-2.87,1.34-3.85c0.03-0.85-0.19-1.71-0.19-1.71l-2.48-10.17c0,0-0.51-1.73,1.05-2.24
            c1.55-0.51,2.12,0.9,2.12,0.9s2.88,9.24,3.19,10.37c0.31,1.13,1.18-0.13,1.18-0.13l0.33-11.66c0,0-0.14-1.94,1.94-2.03
            c2.07-0.09,1.76,2.91,1.76,2.91s-0.26,10.04-0.27,11.21c-0.01,1.17,0.8,0.17,0.8,0.17s3.06-9.2,3.37-10.18
            c0.31-0.97,0.81-2.03,2.28-1.74c1.47,0.29,0.75,3.45,0.75,3.45s-2.71,9.4-2.88,10.11c-0.17,0.71,0.33,0.6,0.33,0.6
            s4.8-6.79,5.18-7.27c0.38-0.48,1.15-1.52,2.51-0.7c1.35,0.82-0.62,3.79-0.62,3.79s-3.67,5.81-4.01,6.38
            c-0.34,0.57-0.31,0.93-0.31,0.93l-0.42,4.56c-1.17,6.14-4.21,7.71-4.21,7.71L42.51,67.9z"
          />
        </svg>

        <p className="MentorVolunteer-content-emphasis">
          Global Careers is seeking alumni volunteers for our Long-Term
          Mentoring Program.
        </p>
        <p>
          This opportunity is open to alumni on a rolling basis from all sectors
          and all levels of experience.
        </p>
        <p>
          Mentorship is a valuable way to make an impact on junior professionals
          while giving back to the SAIS community. The primary purpose is not to
          assist students with their immediate job search and/or with short-term
          networking opportunities, but instead to help them with longer-term
          career exploration and development.
        </p>
        <p>
          Mentors commit to at least three meetings or calls with their
          mentee(s) over the first year and one meeting or call in years to
          follow.
        </p>
        <p>
          The matching campaign for this academic year will take place across
          September and October, when Mentees submit applications for specific
          Mentors after reviewing their profiles. Mentors then select their own
          Mentees from the pool of applications and Global Careers will
          facilitate the introduction in November.
        </p>
        <p>
          If interested, please contact Luke Connolly, Associate Director of
          Global Careers, at <b>lconnolly@jhu.edu</b> with your latest
          professional bio (approx. 10 lines or 2 paragraphs).
        </p>
      </div>
    </div>
  );
};

export default MentorVolunteer;
